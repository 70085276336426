import { handleQueryResolve } from '../utils'

export default function (rxNum) {
  return this.query(`
  SELECT
  SUBSTRING(Fillware.dbo.Rx.RXNumber, PATINDEX('%[^0]%', Fillware.dbo.Rx.RXNumber+'.'), LEN(Fillware.dbo.Rx.RXNumber)) AS "RX NUMBER",
  Rx.RxDate AS "RX DATE",
  Rx.Status AS "STATUS",
  Rx.RxID AS "RX ID",
  Rx.Parentrxnumber AS "PARENT RX NUMBER",
  Rx.SIGFULL AS "SIG",
  Patient.FirstName AS "PATIENT FIRST NAME",
  Patient.LastName AS "PATIENT LAST NAME",
  Rx.DIN,
  DrugRoot.BrandName AS "BRAND NAME",
  DrugRoot.GenericName AS "GENERIC NAME",
  DrugRoot.Strength AS "STRENGTH",
  Rx.PatientID AS "PATIENT ID",
  Doctor.LastName AS "DOCTOR LAST NAME",
  Doctor.FirstName AS "DOCTOR FIRST NAME",
  Rx.Days AS "DAYS",
  Rx.Qtyauthorize AS "QTY AUTHORIZE",
  Rx.Qtydispense AS "QTY DISPENSE",
  Rx.DoctorID AS "DOCTOR ID"
FROM
  ((Fillware.dbo.Rx
INNER JOIN Fillware.dbo.Patient ON Fillware.dbo.Rx.PatientID = Fillware.dbo.Patient.PatientID)
INNER JOIN Fillware.dbo.DrugRoot ON Fillware.dbo.Rx.DIN = Fillware.dbo.DrugRoot.DIN)
INNER JOIN Fillware.dbo.Doctor ON Fillware.dbo.Rx.doctorID = Fillware.dbo.Doctor.DoctorID
WHERE
    Rx.RXNumber LIKE '%${rxNum}%'
GROUP BY
  Rx.RXNumber,
  Rx.RxDate,
  Rx.Status,
  Rx.RxID,
  Rx.Parentrxnumber,
  Rx.SIGFULL,
  Patient.FirstName,
  Patient.LastName,
  Rx.DIN,
  DrugRoot.BrandName,
  DrugRoot.GenericName,
  DrugRoot.Strength,
  Rx.PatientID,
  Doctor.LastName,
  Doctor.FirstName,
  Rx.Days,
  Rx.Qtyauthorize,
  Rx.Qtydispense,
  Rx.DoctorID`).then(handleQueryResolve)
}
